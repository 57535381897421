import React from "react";
import { Route, Routes } from "react-router-dom";

import {
  Home,
  Services,
  Products,
  Details,

} from "./asyncpages";

function Routers() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/products" element={<Products />} />
        <Route path="/details" element={<Details />} />
      </Routes>
    </div>
  );
};
export default Routers;
