
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
// import store from './store/store';
// import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
// import Loader from './components/Loader';

ReactDOM.render(
  // <Provider>
  <Suspense fallback={<div><p>Loading</p></div>}>
    <App />
  </Suspense>,
  // </Provider>,
  document.getElementById('root')
);

reportWebVitals();
